:root {
    --primary-color: #4CAF50;
    --font-stack: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    --base-padding: 10px;
    --color-splash-yellow: #ffcf09;
    --color-splash-purple: #502581;
    --color-vipps: #FF5B24;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.purple {
  color: white;
  background-color: var(--color-splash-purple); 
  padding: 8px 10px;
  border: 2px solid white;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}

button.vipps {
  color: white;
  background-color: var(--color-vipps); 
  padding: 8px 10px;
  border: 2px solid white;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}

button:disabled {
    opacity: 0.5; /* Adjust the opacity value as needed */
    cursor: not-allowed; /* Optional: change the cursor to indicate the button is not clickable */
}

a, a:visited, a:hover, a:active {
  color: inherit;
}
